import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../../../AppContext';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
//* MUI
import { Box, SelectChangeEvent } from '@mui/material';
//* Components
import Loader from '../../../Common/Global/Loader';
import DialogBox from '../../../Common/Layout/DialogBox';
import MainButton from '../../../Common/Buttons/MainButton';
import CancelButton from '../../../Common/Buttons/CancelButton';
import IgaStepper from '../../../Common/Layout/Stepper/IgaStepper';
import InputComponent from '../../../Common/Fields/InputComponent';
import SegmentGroup from '../../Segments/SegmentGroup/SegmentGroup';
import SelectComponent from '../../../Common/Fields/SelectComponent';
import TimeFrameStep from '../../Promotions/PromoStepper/TimeFrameStep';
import AutocompleteComponent from '../../../Common/Fields/AutocompleteComponent';
//* Models
import { IPopup, IPopupSchedule } from '../../../../models/popups';
import { Segment } from '../../../../models/segmets';
//* Enums
import { Actions } from '../../../../enums/ActionEnums';
import { QueryKey } from '../../../../enums/HttpRequestKeyEnums';
//* Queris
import { CreatePopupSchedule, GetPopups, UpdatePopupSchedule } from '../../../../queries/popups';
import { SegmentGroupsQuery } from '../../../../queries/segment';
//* Utils
import { getBrandId, getActiveSegments, handleRequestError } from '../../../../utils/ui';

dayjs.extend(utc);

interface IProps {
  onClose: () => void;
  popupScheduleItem?: IPopupSchedule;
}

const SchedulePopupsDialogForm: React.FunctionComponent<IProps> = ({
  onClose,
  popupScheduleItem
}: IProps) => {
  const queryClient = useQueryClient();
  const steps = ['Details', 'Segments', 'Time frame'];
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [activeStep, setActiveStep] = useState(0);
  const [popupsData, setPopupsData] = useState<IPopup[]>([]);
  const [segmentsData, setSegmentsData] = useState<Segment[]>([]);
  const [hasTimePolicy, setHasTimePolicy] = useState(popupScheduleItem?.hasTimePolicy || false);
  const [popupSchedule, setPopupSchedule] = useState<IPopupSchedule>({
    name: popupScheduleItem?.name || '',
    endDate: popupScheduleItem?.endDate || null,
    dateHours: popupScheduleItem?.dateHours || [],
    timeZone: popupScheduleItem?.timeZone || null,
    startDate: popupScheduleItem?.startDate || null,
    selectedPopup: popupScheduleItem?.selectedPopup || '',
    hasFrequency: popupScheduleItem?.hasFrequency || false,
    frequencyTime: popupScheduleItem?.frequencyTime || null,
    frequencyType: popupScheduleItem?.frequencyType || null,
    isDeactivated: popupScheduleItem?.isDeactivated || false,
    hasTimePolicy: popupScheduleItem?.hasTimePolicy || false,
    frequencyPerUser: popupScheduleItem?.frequencyPerUser || null,
    includedSegmentIds: popupScheduleItem?.includedSegmentIds || [],
    excludedSegmentIds: popupScheduleItem?.excludedSegmentIds || [],
    overrideBlockedSegments: popupScheduleItem?.overrideBlockedSegments || false
  });

  const [included, setIncluded] = useState<string[]>(
    segmentsData.filter((s) => popupSchedule.includedSegmentIds.includes(s.id)).map((s) => s.name)
  );

  const [excluded, setExcluded] = useState<string[]>(
    segmentsData.filter((s) => popupSchedule.excludedSegmentIds.includes(s.id)).map((s) => s.name)
  );

  useEffect(() => {
    if (popupScheduleItem) {
      const includedNamesArray: string[] = [];
      const excludedNamesArray: string[] = [];

      popupScheduleItem.includedSegmentIds.forEach((segmentId) => {
        const segment = segmentsData.find((segment) => segment.id === segmentId);

        if (segment) {
          includedNamesArray.push(segment.name);
        }
      });

      popupScheduleItem.excludedSegmentIds.forEach((segmentId) => {
        const segment = segmentsData.find((segment) => segment.id === segmentId);

        if (segment) {
          excludedNamesArray.push(segment.name);
        }
      });

      setIncluded(includedNamesArray);
      setExcluded(excludedNamesArray);
    }
  }, [segmentsData]);

  useQuery({
    queryKey: [QueryKey.GetPopups, brandId],
    queryFn: () => GetPopups({ brandId }),
    onSuccess: ({ data }) => {
      const filteredPopups = data.filter((popupData: IPopup) => popupData.isActive);
      setPopupsData(filteredPopups);
    },
    refetchOnMount: 'always',
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  useQuery({
    queryKey: [QueryKey.SegmentGroups, brandId],
    queryFn: () => SegmentGroupsQuery(brandId),
    refetchOnMount: 'always',
    onSuccess: ({ data }) => {
      const segments = [] as Segment[];
      (data as SegmentGroup[]).map((sg) => segments.push(...sg.segments));
      setSegmentsData(getActiveSegments(data as SegmentGroup[]));
    }
  });

  const createPopupScheduleItem = useMutation(CreatePopupSchedule, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Created ${data.name} pop-up schedule item`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopupsScheduleItems, brandId])
  });

  const updatePopupScheduleItem = useMutation(UpdatePopupSchedule, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Updated "${data.name}" pop-up schedule item`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopupsScheduleItems, brandId])
  });

  const handleIncludedChange = (event: SelectChangeEvent<typeof included>) => {
    const {
      target: { value }
    } = event;
    const names = typeof value === 'string' ? value.split(',') : value;

    setIncluded(names);
    setExcluded(excluded.filter((e) => !names.includes(e)));
    setPopupSchedule({
      ...popupSchedule,
      includedSegmentIds: segmentsData.filter((s) => names.includes(s.name)).map((s) => s.id)
    });
  };

  const handleExcludedChange = (event: SelectChangeEvent<typeof excluded>) => {
    const {
      target: { value }
    } = event;
    const names = typeof value === 'string' ? value.split(',') : value;

    setExcluded(names);
    setIncluded(included.filter((e) => !names.includes(e)));
    setPopupSchedule({
      ...popupSchedule,
      excludedSegmentIds: segmentsData.filter((s) => names.includes(s.name)).map((s) => s.id)
    });
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box className="step-container">
            <Box className="step-title">Details</Box>
            <InputComponent
              id="name"
              label="Name"
              value={popupSchedule.name}
              onChange={(event) => setPopupSchedule({ ...popupSchedule, name: event.target.value })}
            />
            <AutocompleteComponent
              label="Select Pop-up"
              id="template-autocomplete"
              value={popupSchedule.selectedPopup}
              options={popupsData.map((popup) => popup.name)}
              onChange={(_, newValue) =>
                setPopupSchedule({ ...popupSchedule, selectedPopup: newValue })
              }
            />
          </Box>
        );
      case 1:
        return (
          <Box className="step-container">
            <Box className="step-title">Segments</Box>
            <SelectComponent
              hideNoneValue
              value={included}
              isSingle={false}
              label="Include Segments"
              onChange={handleIncludedChange}
              options={segmentsData.map((segment) => segment.name)}
            />
            <SelectComponent
              hideNoneValue
              value={excluded}
              isSingle={false}
              label="Exclude Segments"
              onChange={handleExcludedChange}
              options={segmentsData.map((segment) => segment.name)}
            />
          </Box>
        );
      case 2:
        return (
          <TimeFrameStep
            hasTimePolicy={hasTimePolicy}
            endDate={popupSchedule.endDate}
            timeZone={popupSchedule.timeZone}
            startDate={popupSchedule.startDate}
            dateHours={popupSchedule.dateHours}
            hasFrequency={popupSchedule.hasFrequency}
            frequencyTime={popupSchedule.frequencyTime}
            frequencyType={popupSchedule.frequencyType}
            frequencyPerUser={popupSchedule.frequencyPerUser}
            overrideBlockedSegments={popupSchedule.overrideBlockedSegments}
            setHasTimePolicy={setHasTimePolicy}
            setEndDate={(endDate) => setPopupSchedule({ ...popupSchedule, endDate })}
            setTimeZone={(timeZone) => setPopupSchedule({ ...popupSchedule, timeZone })}
            setStartDate={(startDate) => setPopupSchedule({ ...popupSchedule, startDate })}
            setDateHours={(dateHours) => setPopupSchedule({ ...popupSchedule, dateHours })}
            setHasFrequency={(hasFrequency) => setPopupSchedule({ ...popupSchedule, hasFrequency })}
            setFrequencyTime={(frequencyTime) =>
              setPopupSchedule({ ...popupSchedule, frequencyTime })
            }
            setFrequencyType={(frequencyType) =>
              setPopupSchedule({ ...popupSchedule, frequencyType })
            }
            setFrequencyPerUser={(frequencyPerUser) =>
              setPopupSchedule({ ...popupSchedule, frequencyPerUser })
            }
            setOverrideBlockedSegments={(overrideBlockedSegments) =>
              setPopupSchedule({ ...popupSchedule, overrideBlockedSegments })
            }
          />
        );
      default:
        return <h3>Step not found</h3>;
    }
  };

  const handleNextClicked = () => {
    if (activeStep + 1 === steps.length) {
      const input = {
        ...popupSchedule,
        hasTimePolicy
      } as IPopupSchedule;

      if (popupScheduleItem && popupScheduleItem.id) {
        updatePopupScheduleItem.mutate({ id: popupScheduleItem.id, input });
      } else {
        createPopupScheduleItem.mutate({ brandId, input });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const stepIsValid = (): boolean => {
    switch (activeStep) {
      case 0:
        return (
          popupSchedule.name.length > 2 &&
          !!popupSchedule.selectedPopup &&
          popupSchedule.selectedPopup.length > 0
        );
      case 1:
        return included.length > 0;
      case 2:
        return !!popupSchedule.startDate && !!popupSchedule.timeZone;
      default:
        return false;
    }
  };

  const dialogTitleText = 'Pop-up schedule';

  return (
    <DialogBox
      width={1400}
      onClose={onClose}
      title={popupScheduleItem ? `Edit ${dialogTitleText}` : `Create New ${dialogTitleText}`}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IgaStepper steps={steps} activeStep={activeStep}>
          {getStepContent()}
        </IgaStepper>
        <Box className="form-actions" sx={{ mt: '16px' }}>
          <Box className="form-group-buttons">
            <CancelButton
              text="Back"
              id="popup-schedule-back"
              disabled={activeStep === 0}
              sx={{ height: '36px', width: '48px' }}
              onClick={() => setActiveStep(activeStep - 1)}
            />
            <MainButton
              id="popup-schedule-next"
              disabled={!stepIsValid()}
              onClick={handleNextClicked}
              text={activeStep + 1 === steps.length ? 'Save' : 'Next'}
            />
          </Box>
        </Box>
      </Box>
      <Loader loading={createPopupScheduleItem.isLoading || updatePopupScheduleItem.isLoading} />
    </DialogBox>
  );
};

export default SchedulePopupsDialogForm;
