import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, IconButton, SelectChangeEvent } from '@mui/material';
import InputComponent from '../../../Common/Fields/InputComponent';
import { AppContext } from '../../../../AppContext';
import AddButton from '../../../Common/Buttons/AddButton';
import AutocompleteComponent from '../../../Common/Fields/AutocompleteComponent';
import { JourneyStepType } from '../../../../enums/EntityEnums';
import { JourneyStage } from '../../../../models/journeys';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { getJourneyStageParent } from '../../../../utils/ui';
import SelectComponent from '../../../Common/Fields/SelectComponent';
import { Segment } from '../../../../models/segmets';
import { colors } from '../../../../utils/theme';
import SettingsIcon from '@mui/icons-material/Settings';
import StepConfig from './StepConfig';
import { TimeZone } from '../../../../models/global';

const iconButtonProps = {
  height: '48px',
  width: '48px',
  marginRight: '16px'
};

interface IProps {
  allSteps: JourneyStage;
  handleStepSave: (step: JourneyStage) => void;
  handleDelete: () => void;
  setIsEditing: () => void;
  segments: Segment[];
  includedSegments: string[];
  excludedSegments: string[];
  setSegments: (event: SelectChangeEvent<string[]>, type: 'included' | 'excluded') => void;
}

const StepEditor: React.FunctionComponent<IProps> = ({
  handleStepSave,
  allSteps,
  handleDelete,
  setIsEditing,
  segments,
  includedSegments,
  excludedSegments,
  setSegments
}: IProps) => {
  const { state } = useContext(AppContext);
  const { selectedJourneyStage } = state;
  const buildOptions = () => {
    let res = [allSteps] as JourneyStage[];
    const addOption = (stage: JourneyStage) => {
      if (stage.children && stage.children.length > 0) {
        res = [...res, ...stage.children.filter((c) => c.id !== selectedJourneyStage?.id)];
        stage.children.forEach((child) => addOption(child));
      }
    };
    addOption(allSteps);
    return res;
  };

  const [name, setName] = useState(selectedJourneyStage?.name ?? '');
  const [type, setType] = useState(selectedJourneyStage?.type ?? null);
  const [parent, setParent] = useState(getJourneyStageParent(selectedJourneyStage, allSteps));
  const [options, setOptions] = useState(buildOptions());
  const [openConfig, setOpenConfig] = useState(false);
  const [channel, setChannel] = useState<string>(selectedJourneyStage?.channel ?? '');
  const [emailSender, setEmailSender] = useState<number | null>(
    selectedJourneyStage?.emailSender ?? null
  );
  const [template, setTemplate] = useState<string | null>(selectedJourneyStage?.template ?? null);
  const [smsText, setSmsText] = useState<string | null>(selectedJourneyStage?.smsText ?? '');
  const [timezone, setTimezone] = useState(selectedJourneyStage?.timezone ?? null);
  const [dateHours, setDateHours] = useState(selectedJourneyStage?.dateHours ?? []);
  const [waitTime, setWaitTime] = useState(selectedJourneyStage?.waitTime ?? null);
  const [waitType, setWaitType] = useState(selectedJourneyStage?.waitType ?? null);

  useEffect(() => {
    setName(state.selectedJourneyStage?.name ?? '');
    setType(state.selectedJourneyStage?.type ?? null);
    setParent(getJourneyStageParent(selectedJourneyStage, allSteps));
    setOptions(buildOptions());
    setChannel(selectedJourneyStage?.channel ?? '');
    setEmailSender(selectedJourneyStage?.emailSender ?? null);
    setTemplate(selectedJourneyStage?.template ?? null);
    setSmsText(selectedJourneyStage?.smsText ?? '');
    setTimezone(selectedJourneyStage?.timezone ?? null);
    setDateHours(selectedJourneyStage?.dateHours ?? []);
    setWaitTime(selectedJourneyStage?.waitTime ?? null);
    setWaitType(selectedJourneyStage?.waitType ?? null);
  }, [state.selectedJourneyStage]);

  useEffect(() => {
    setOptions(buildOptions());
  }, [allSteps]);

  const saveStep = () => {
    const initial = state.selectedJourneyStage as JourneyStage;
    const input = {
      ...initial,
      name,
      type,
      parent,
      channel,
      emailSender,
      template,
      smsText,
      timezone,
      dateHours,
      waitTime,
      waitType
    };
    handleStepSave(input);
  };

  const handleTriggerChange = (newValue: JourneyStepType) => {
    setType(newValue);
    setIsEditing();
    setChannel('');
    setEmailSender(null);
    setTemplate(null);
    setSmsText('');
    setTimezone(null);
    setDateHours([]);
    setWaitTime(null);
    setWaitType(null);
  };

  return (
    <Box>
      <InputComponent
        id="step-name"
        label="Step Title"
        value={name}
        containerStyles={{ width: '100%' }}
        onChange={(e) => {
          setIsEditing();
          setName(e.currentTarget.value);
        }}
        disabled={name === 'Target Audience'}
      />
      <Box display="flex" gap="20px" sx={{ mt: '20px' }}>
        {type !== JourneyStepType.TargetAudience && (
          <>
            <AutocompleteComponent
              id="step-type"
              label="Step Trigger Type"
              value={type}
              options={Object.values(JourneyStepType).filter(
                (o) => o !== JourneyStepType.TargetAudience
              )}
              onChange={(_, newValue) => handleTriggerChange(newValue)}
              containerStyles={{ width: '100%' }}
            />
            <AutocompleteComponent
              id="step-parent"
              label="Step parent"
              value={parent}
              options={options}
              getOptionLabel={(s) => s.name}
              onChange={(_, newValue) => {
                setParent(newValue);
                setIsEditing();
              }}
              containerStyles={{ width: '100%' }}
            />
          </>
        )}
      </Box>
      {type === JourneyStepType.TargetAudience && (
        <>
          <SelectComponent
            label="Included Segments"
            value={includedSegments}
            isSingle={false}
            onChange={(e) => setSegments(e, 'included')}
            options={segments.map((s) => s.name)}
            hideNoneValue
          />
          <SelectComponent
            label="Excluded Segments"
            value={excludedSegments}
            isSingle={false}
            onChange={(e) => setSegments(e, 'excluded')}
            options={segments.map((s) => s.name)}
            containerStyles={{ mt: '14px' }}
          />
        </>
      )}
      {type === JourneyStepType.Wait && (
        <Box sx={{ display: 'flex', gap: '20px', mt: '20px' }}>
          <AutocompleteComponent
            id="frequency-per-user"
            label="Wait time"
            value={waitTime}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            getOptionLabel={(opt) => opt.toString()}
            onChange={(_, newValue) => {
              setWaitTime(newValue);
              setIsEditing();
            }}
            containerStyles={{ width: '100%' }}
          />

          <AutocompleteComponent
            id="frequency-type"
            label="Wait Type"
            value={waitType}
            options={['Week/s', 'Day/s']}
            onChange={(_, newValue) => {
              setWaitType(newValue);
              setIsEditing();
            }}
            containerStyles={{ width: '100%' }}
          />
        </Box>
      )}
      <Box className="form-actions" sx={{ mt: '20px' }}>
        <Box className="form-group-buttons">
          {type !== JourneyStepType.TargetAudience && (
            <IconButton
              onClick={() => {
                setIsEditing();
                handleDelete();
              }}
              sx={{ ...iconButtonProps, marginRight: 0 }}
            >
              <DeleteOutlineSharpIcon sx={{ color: 'error.main' }} />
            </IconButton>
          )}

          <AddButton id="step-save" text="Save Step" onClick={saveStep} />
        </Box>
        {type !== JourneyStepType.TargetAudience && type !== JourneyStepType.Wait && (
          <Button
            sx={{
              color: colors.gradients.pinkRgb,
              width: '95px',
              p: '0px',
              '&: hover': {
                textDecoration: 'underline',
                backgroundColor: 'white'
              }
            }}
            onClick={() => {
              setOpenConfig(true);
              setIsEditing();
            }}
          >
            <SettingsIcon sx={{ width: '15px', height: '15px' }} /> configure
          </Button>
        )}
      </Box>
      {openConfig && (
        <StepConfig
          stepName={name}
          onClose={() => {
            saveStep();
            setOpenConfig(false);
          }}
          channel={channel}
          setChannel={setChannel}
          smsText={smsText as string}
          setSmsText={setSmsText}
          emailSender={emailSender}
          setEmailSender={setEmailSender}
          template={template}
          setTemplate={setTemplate}
          timezone={timezone}
          setTimezone={setTimezone}
          dateHours={dateHours}
          setDateHours={setDateHours}
        />
      )}
    </Box>
  );
};

export default StepEditor;
